import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';

const examples = [
  {
    title: "Daily Water Intake Tracker",
    description: "Log your daily water consumption with a single tap.",
    tags: ["Health", "Tracking"]
  },
  {
    title: "Quick Meeting Notes",
    description: "Instantly create and save meeting notes with predefined templates.",
    tags: ["Productivity", "Work"]
  },
  {
    title: "Smart Home Control",
    description: "Adjust lights, temperature, and music with voice commands.",
    tags: ["Home", "Automation"]
  },
  {
    title: "Expense Logger",
    description: "Quickly log expenses and categorize them for easy budgeting.",
    tags: ["Finance", "Tracking"]
  }
];

export default function ExampleShortcuts({ onCreateShortcut }) {
  return (
    <div className="mt-12">
      <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Example Shortcuts</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {examples.map((example, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="p-6">
              <h3 className="text-lg font-medium mb-2">{example.title}</h3>
              <p className="text-sm text-gray-600 mb-4">{example.description}</p>
              <div className="flex flex-wrap gap-2 mb-4">
                {example.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="px-2 py-1 bg-gray-200 text-gray-700 rounded-full text-xs font-semibold">
                    {tag}
                  </span>
                ))}
              </div>
              <button 
                className="w-full px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md shadow hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-200 ease-in-out flex items-center justify-center"
                onClick={() => onCreateShortcut(example.title)}
              >
                Create Shortcut
                <ArrowRightIcon className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
