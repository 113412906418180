import React, { useState } from 'react';
import { ArrowRightIcon, ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/solid';
import { FaTwitter } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import ExampleShortcuts from './components/ExampleShortcuts';

function App() {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [shortcut, setShortcut] = useState(null);
  const [error, setError] = useState(null);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [lastGeneratedPrompt, setLastGeneratedPrompt] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await generateShortcut(prompt);
  };

  const generateShortcut = async (shortcutPrompt) => {
    setIsLoading(true);
    setError(null);

    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (Math.random() < 0.1) {
        throw new Error('Failed to generate shortcut. Please try again.');
      }
      setShortcut('Generated Shortcut');
      setLastGeneratedPrompt(shortcutPrompt);
      setPrompt(shortcutPrompt);
      toast.success('Shortcut generated successfully!');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
      toast.error('Failed to generate shortcut');
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = () => {
    const tweetText = encodeURIComponent(
      `I just generated a custom Apple Shortcut for "${prompt}"! Check it out: ${window.location.href}`
    );
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
  };

  const handleCreateExampleShortcut = (exampleTitle) => {
    setPrompt(exampleTitle);
    generateShortcut(exampleTitle);
  };

  return (
    <main className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col items-center justify-center p-4 sm:p-8">
      <div className="w-full max-w-3xl">
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-4 text-gray-800 animate-fade-in">
          Generate Your Custom Apple Shortcut
        </h1>
        <p className="text-lg sm:text-xl text-center mb-8 text-gray-600 animate-fade-in-delay">
          Type your request, and we'll instantly craft a personalized Apple Shortcut just for you.
        </p>
        <form onSubmit={handleSubmit} className="mb-8 animate-slide-up">
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="e.g. 'Create a shortcut to track my daily water intake'"
              className="flex-grow shadow-sm px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Shortcut prompt"
            />
            <button
              type="submit"
              className={`w-full sm:w-auto px-6 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center justify-center ${
                isLoading || prompt.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLoading || prompt.trim() === ''}
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating...
                </>
              ) : shortcut && prompt === lastGeneratedPrompt ? (
                <>
                  Regenerate
                  <ArrowRightIcon className="ml-2 h-5 w-5" />
                </>
              ) : (
                <>
                  Generate Shortcut
                  <ArrowRightIcon className="ml-2 h-5 w-5" />
                </>
              )}
            </button>
          </div>
        </form>
        {error && (
          <div className="mb-8 border border-red-200 bg-red-50 rounded-md animate-shake">
            <div className="p-4 text-red-700">{error}</div>
          </div>
        )}
        {shortcut && !isLoading && (
          <div className="mb-8 bg-white rounded-lg shadow-md animate-fade-in">
            <div className="p-6">
              <div className="flex justify-center mb-4">
                <div className="w-16 h-16 bg-gradient-to-br from-blue-400 to-purple-500 rounded-lg shadow-md flex items-center justify-center">
                  <svg
                    className="w-10 h-10 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center mb-4 text-gray-800">{shortcut}</h2>
              <div className="flex justify-center">
                <button className="px-6 py-2 bg-gray-200 text-gray-800 font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-200 ease-in-out">
                  Download Shortcut
                </button>
              </div>
            </div>
          </div>
        )}
        {shortcut && !isLoading && (
          <div className="flex flex-wrap justify-center gap-4 animate-fade-in-delay">
            <button
              className={`px-4 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-200 ease-in-out flex items-center ${
                liked ? 'bg-green-100 border-green-500' : ''
              }`}
              onClick={() => {
                setLiked(true);
                setDisliked(false);
                toast.success('Thanks for your feedback!');
              }}
            >
              <ThumbUpIcon className={`mr-2 h-5 w-5 ${liked ? 'text-green-500' : ''}`} />
              Like
            </button>
            <button
              className={`px-4 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-200 ease-in-out flex items-center ${
                disliked ? 'bg-red-100 border-red-500' : ''
              }`}
              onClick={() => {
                setDisliked(true);
                setLiked(false);
                toast.success('Thanks for your feedback!');
              }}
            >
              <ThumbDownIcon className={`mr-2 h-5 w-5 ${disliked ? 'text-red-500' : ''}`} />
              Dislike
            </button>
            <button
              className="px-4 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-200 ease-in-out flex items-center"
              onClick={handleShare}
            >
              <FaTwitter className="mr-2 h-5 w-5" />
              Tweet This
            </button>
          </div>
        )}
        <ExampleShortcuts onCreateShortcut={handleCreateExampleShortcut} />
      </div>
      <footer className="mt-8 text-center text-sm text-gray-500">
        <a
          href="https://twitter.com/yourusername"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-blue-500 transition-colors"
        >
          Follow me on Twitter
        </a>
      </footer>
      <Toaster position="bottom-center" />
    </main>
  );
}

export default App;
